import PropTypes from 'prop-types'
import React from 'react'
import codeAlignment from '../images/code_alignment.png'
import comingSoon from '../images/coming_soon.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">INTRO</h2>
          {
          //<span className="image main">
          //  <img src={pic01} alt="" />
          //</span>
          }
          <p>
            @ryotakewa のポートフォリオとして作成。
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">WORK</h2>
          { /* BLOCK START */ }
          <h3>
            <a href="https://codealignment.takewa.io" target="_blank" rel="noreferrer noopener">Code alignment</a>
          </h3>
          <span className="image main">
            <a href="https://codealignment.takewa.io" target="_blank" rel="noreferrer noopener">
              <img src={codeAlignment} alt="" />
            </a>
          </span>
          <p>
            Webでコードを整えられるツールです。
          </p>
          { /* BLOCK END */ }

          { /* BLOCK START */ }
          <h3>
            Skill map
          </h3>
          <span className="image main">
            <img src={comingSoon} alt="" />
          </span>
          <p>
            開発中
          </p>
          <p>
            スキルや経歴を管理し、好きなサイトに埋め込みができるサービスです。
          </p>
          { /* BLOCK END */ }

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">ABOUT</h2>
          {
          //<span className="image main">
          //  <img src={pic03} alt="" />
          //</span>
          }
          <p>
            30以上の案件に携わり、設計から運用までを担当。
            <br />
            主にプレイングマネージャーをしており、様々な役割・技術も可能。
            <br />
            モノトーンを好む（特に黒）。
          </p>
          <h3>Languages</h3>
          <ul>
            <li>Ruby</li>
            <li>JavaScript</li>
            <li>PHP</li>
            <li>Scala</li>
            <li>C</li>
            <li>C++</li>
            <li>C#</li>
            <li>Java</li>
            <li>VB.NET</li>
            <li>etc</li>
          </ul>
          <h3>Frameworks</h3>
          <ul>
            <li>Ruby on Rails</li>
            <li>React</li>
            <li>Laravel</li>
            <li>CodeIgniter</li>
            <li>Yii2</li>
            <li>PlayFramework</li>
            <li>JAX-RS</li>
            <li>Struts2</li>
            <li>etc</li>
          </ul>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">CONTACT</h2>
          <p>
            お仕事や技術的なご相談はSNSへ。
          </p>
          {
          //<form method="post" action="#">
          //  <div className="field half first">
          //    <label htmlFor="name">Name</label>
          //    <input type="text" name="name" id="name" />
          //  </div>
          //  <div className="field half">
          //    <label htmlFor="email">Email</label>
          //    <input type="text" name="email" id="email" />
          //  </div>
          //  <div className="field">
          //    <label htmlFor="message">Message</label>
          //    <textarea name="message" id="message" rows="4"></textarea>
          //  </div>
          //  <ul className="actions">
          //    <li>
          //      <input type="submit" value="Send Message" className="special" />
          //    </li>
          //    <li>
          //      <input type="reset" value="Reset" />
          //    </li>
          //  </ul>
          //</form>
          }
          <ul className="icons">
            <li>
              <a href="https://twitter.com/ryotakewa" className="icon fa-twitter" target="_blank" rel="noreferrer noopener">
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/takewaryo" className="icon fa-facebook" target="_blank" rel="noreferrer noopener">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ryotakewa/" className="icon fa-instagram" target="_blank" rel="noreferrer noopener">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/ryotakewa" className="icon fa-github" target="_blank" rel="noreferrer noopener">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
